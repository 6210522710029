.app {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.switchboard {
	box-sizing: border-box;
	flex-basis: 0;
	flex-grow: 1;
	display: flex;
	flex-wrap: wrap;
	padding: 10%;
	justify-content: center;
	align-items: center;
}

.controls {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	box-sizing: border-box;
	flex-basis: auto;
	background-color: #444;
	box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
	justify-content: center;
}

.controls > * {
	box-sizing: border-box;
	height: 32px;
	margin: 8px 8px;
}

.controls > button {
	background-color: #ccc;
	text-decoration: none;
	border: none;
	border-radius: 3px;
}

.controls > button:hover {
	background-color: #eee;
}

.component {
	position: relative;
	margin: 16px;
	width: 128px;
	height: 128px;
	background-color: #444;
	border-radius: 8px;
	box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
}

.component > .close {
	position: absolute;
	right: 8px;
	top: 4px;
	color: white;
	cursor: pointer;
}

.pin-name {
	color: #ccc;
	text-align: center;
	position: absolute;
	top: 75%;
	width: 100%;
}

.led {
	--size: 24px;
	position: absolute;
	top: calc(50% - var(--size));
	bottom: calc(50% - var(--size));
	left: calc(50% - var(--size));
	right: calc(50% - var(--size));
	background-color: rgba(#0f0, 0.5);
	border-radius: var(--size);
	box-shadow: inset 0 0 calc(var(--size) / 4) rgba(0, 0, 0, 0.3);
}

.switch {
	--width: 60px;
	--height: 34px;
	top: calc(50% - (var(--height) / 2));
	left: calc(50% - (var(--width) / 2));
	position: relative;
	display: block;
	width: var(--width);
	height: var(--height);
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 100%;
	height: 100%;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
}

.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.2s;
	transition: 0.2s;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

input:checked + .slider {
	background-color: #2196f3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
